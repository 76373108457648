import { Container } from "react-bootstrap"

const SearchHistory = () => {
    return (
        <Container>
            Coming Soon...
        </Container>
    )
}

export default SearchHistory
