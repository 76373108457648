import { Container } from "react-bootstrap"

const LovedRecipeList = () => {
    return (
        <Container>
            Coming Soon...
        </Container>
    )
}

export default LovedRecipeList
